//
// @name Button
// @description Your standard form button.
//
// @state :hover - Highlights when hovering.
// @state :disabled - Dims the button when disabled.
// @state .primary - Indicates button is the primary action.
// @state .smaller - A smaller button
//
// @markup
//   <button>This is a button</button>
//

$border-radius: $border-radius !default;
$button-color: $button-color !default;
$button-font: $base-font-family !default;
$button-font-size: $base-font-size !default;
$button-weight: $base-font-weight !default;
$button-line-height: $base-line-height !default;
$button-text-align: center !default;
$button-margin-bottom: 0;
$button-display: inline-block;
$button-border-width: 3px !default;
$button-border-color: #aaccda !default;
$button-border-style: solid !default;

// We use these to control various hover effects.
$button-function-factor: -20% !default;

// Styles
$button-bg-color: transparent !default;
$button-bg-hover: scale-color($button-bg-color, $lightness: $button-function-factor) !default;

@mixin button-base($style: true, $display: $button-display) {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	position: relative;
	text-decoration: none;
	font-family: $button-font;
	font-weight: $button-weight;
	text-align: $button-text-align;
	font-style: italic;
	margin: 0 0 $button-margin-bottom;
	border: none;

	@if $display {
		display: $display;
	}
}



@mixin button-size($padding: 0.5rem, $full-width: false) {
	@if $padding {
		padding: $padding ($padding * 2) ($padding) ($padding * 2);
	}

	@if $full-width {
		@if $padding {
			padding-bottom: $padding + rem-calc(1);
			padding-top: $padding;
		}
		@else if $padding == false {
			padding-bottom: 0;
			padding-top: 0;
		}

		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
}


@mixin button-style($bg: $button-bg-color, $radius: false, $disabled: false, $bg-hover: null, $border-color: $button-border-color, $border-width: $button-border-width, $border-style: $button-border-style, $border-hover: transparent) {
	background-color: $bg;
	border-radius: $radius;
	color: $button-color;
	font-size: $button-font-size;
	font-weight: $button-weight;
	line-height: $button-line-height;
	border: $border-width $border-style $border-color;
	transition: color 200ms, background-color 200ms, border 200ms;
	transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);

	@if $bg-hover {
		&:hover {
			background-color: $bg-hover;
			border-color: $border-hover;
		}
	}
	@else {
		&:hover {
			background-color: darken($bg, 10%);
		}
	}
}



a.button, a .button, button.button, input[type="submit"] {
	@include button-base();
	@include button-style( $radius: $border-radius, $border-hover: #748a94);
	@include button-size($padding: 7px);
	position: relative;
	overflow: hidden;
	text-decoration: none;
	&:hover {
		border-color: #4c616b;
	}

	&:hover:after {
		transform: translate3d(0, 0, 0);
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: -#{$button-border-width};
		width: 105%; //Extra size prevents weird 1px gap on Android Chrome
		height: 105%;
		transform-origin: bottom left;
		transition: transform 0.15s cubic-bezier(0.22, 0.61, 0.36, 1);
		z-index: -1;
	}

	&:after {
		background: #b2c8e0;
		transform: translate3d(0, -100%, 0) rotate(-30deg);
	}

	&:hover {
		//color: #fff;
	}
	//&.primary{ @include button-style( $bg-hover: $white); }
	//&.secondary{ @include button-style($bg: $white, $bg-hover: $white); }
}
input[type="submit"]{
	margin-top: $small-margin;
	}
input[type="submit"]:hover{
	background: #b2c8e0;
}
input[type="reset"] {
	@include button-base();
	@include button-style($radius: $border-radius);
	@include button-size($padding: 7px);
}
