﻿
.o-sigil{
	background-image: url('../Icons/rotvoll-sigill.svg');
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	width: 100px;
	height: 80px;
	display: inline-block;
	&--small{
		width: 70px;
		height: 40px;
	}
}