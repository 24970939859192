﻿:root{
	font-size: 16px;
}
body {
    color: $base-font-color;
    font-family: $base-font-family;
    font-weight: $base-font-weight;
    line-height: scale(2, unitless);
	@media #{$small-only}{
		//font-size: 1.1rem;
	}
}

a, .link {
    color: $link-color;
    text-decoration: none;
	transition: color 300ms ease-in-out;
    &:hover {
        color: $link-hover-color;
    }
    &:visited {
        color: currentColor;
    }
}
.link{
	text-decoration: underline;
}
a.no-link-style {
    color: $text-color;
    text-decoration: none;
}
h1, h2, h3, h4, h5, h6, 
.h1, .h2, .h3, .h4, .h5, .h6 {
    color: currentColor;
}
// Do note that these are classes, not elements
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;
}

.h1, .h2 {
    line-height: 1.3;
}

.h3, .h4 {
    line-height: 1.3;
}

.h1, h1 {
    font-size: scale(5);

    @media #{$large-up} {
        font-size: scale(6);
    }
}

.h2, h2 {
    font-size: scale(4);

    @media #{$large-up} {
        font-size: scale(5);
    }
}

.h3, h3 {
    font-size: scale(2);

    @media #{$large-up} {
        font-size: scale(4);
    }
}

.h4, h4 {
    font-size: scale(2);

    @media #{$large-up} {
        font-size: scale(3);
    }
}

.h5, h5 {
    font-size: scale(1);

    @media #{$large-up} {
        font-size: scale(2);
    }
}

.h6, h6 {
    font-size: 1em;
}

.h1.not-bold,
.h2.not-bold,
.h3.not-bold,
.h4.not-bold,
.h5.not-bold,
.h6.not-bold {
    font-weight: normal;
}
//.quiet { color: $quiet-color; }
//.loud  { color: $loud-color; }
.alternate-heading {
    color: $alternate-heading-color;
}

.smaller {
    font-size: 80%;
}

.larger {
    font-size: 120%;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: 700;
    @extend .loud !optional;
}

.uppercase {
    text-transform: uppercase;
}

.center {
    text-align: center;
}

blockquote, q {
    quotes: none;
    border: 2px solid #aaccda;
    padding: 2em;
	margin: 3rem auto 3rem;
    text-align: center;
    font-weight: normal;
	font-family: $heading-font-family;
    position: relative;
	width: 90%;
    font-size: 1.5rem;
	quotes: "“" "”" "‘" "’";
}

blockquote:after, blockquote:before {
    content: open-quote;
    color: #000;
    font-family: "Plantin";
    position: absolute;
    top: -0.5em;
	font-size: 1.5em;
    line-height: 1em;
    left: 50%;
	transform: translateX(-50%);
    padding: 5px 20px;
    background-color: #eaf1f4;
}

blockquote:after {
	content: close-quote;
    bottom: -1em;
    top: auto;
    /* line-height: 1em; */
}

@media #{$small-only} {
    .s-center {
        text-align: center;
    }
}

@media #{$small-up} {
    .s-up-center {
        text-align: center;
    }
}

@media #{$medium-only} {
    .m-center {
        text-align: center;
    }
}

@media #{$medium-up} {
    .m-up-center {
        text-align: center;
    }
}

@media #{$large-up} {
    .l-center {
        text-align: center;
    }
}

@media #{$large-only} {
    .l-up-center {
        text-align: center;
    }
}

.s-up-center {
}

.m-center {
}

.m-up-center {
}

.l-center {
}

.l-up-center {
}

.unordered-list {
    list-style-type: disc;
}

.ordered-list {
    list-style: decimal;
}

.horizontal-list > * {
    display: inline-block;
    list-style: none;
}

.block-margins {
    margin: 1em 0;
}

.small-links {
    font-size: 0.85em;
}

.typography {
    i, em {
        @extend .italic;
    }
	a{
		@extend .link;
	}
    b, strong {
        @extend .bold;
    }

    h1, .h1 {
        @extend .h1;
        margin: 1em 0 0.5em;
    }

    h2, .h2 {
        @extend .h2;
        margin: 1em 0 0.5em;
    }

    h3, .h3 {
        @extend .h3;
        margin: 1em 0 0.5em;
    }

    h4, .h4 {
        @extend .h4;
        margin: 1em 0 0.5em;
    }

    h5, .h5 {
        @extend .h5;
        margin: 1em 0 0.5em;
    }

    h6, .h6 {
        @extend .h6;
        margin: 1em 0 0.5em;
    }

    p, ul, ol, pre {
        @extend .block-margins;
    }

    ul, ol {
        list-style-position: inside;
        padding-left: 1em;
    }

    ul {
        @extend .unordered-list;
    }

    ol {
        @extend .ordered-list;
    }

    pre, code {
        @extend .fixed !optional;
    }
}

.main-content {
    word-wrap: break-word;
}
