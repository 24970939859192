/**
 * @name Forms
 * @order 3
 */

/**
 * @name Input fields
 * @state :focus - Focussed state
 * @state :hover - Hover state
 * @state .error - Error state
 * @markup
 * <form><label>Label:</label> <input type="text" class="{{{escaped}}}"></form>
 */

$form-font-size:		$base-font-size !default;
$form-font-family:		$base-font-family !default;
$form-text-color:		$text-color !default;
$form-padding:			13px !default;
$form-border-width:		0 !default;
$form-border-color:		#cccccc !default;
$form-focus-border:		#454545 !default;
$form-bg:				transparent !default;
$form-focus-bg:			transparent !default;
$form-border-radius:	2px !default;
$form-border-style:		solid !default;



@mixin form-base{
	font-size: $form-font-size;
	font-family: $form-font-family;
	padding: $form-padding*0.5 $form-padding $form-padding;
	background-color: $form-bg;
	border-width: $form-border-width;
	border-color: $form-border-color;
	border-radius: $form-border-radius;
	border-style: $form-border-style;
	color: $form-text-color;
	font-weight: bold;	
	&:focus, &:hover{
		outline: 0;
		border-color: $form-focus-border;
		background-color: $form-focus-bg;
	}
	&.large{}

}


textarea, input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']):not([type='reset']) {
	@include form-base;
	width: 100%;
    transition: border-color 200ms;
	border-bottom: 2px solid #aaccda;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button, 
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input[type="checkbox"] {
}

.formTitle {
    display: none;
}

.FormContainer {

    &.form-submitted {
        text-align: center;
        font-size: 140%;
    }
}

.LabelWrapper{
	width: 100%;
	background-color: white;
	padding-left: 12px;
	padding-top: 0.25em;
	font-size: scale(-2);
	letter-spacing: 0.1em;
}
.FormFieldWrapper{
	margin-bottom: 1em;
}

.termsLabel {
    text-decoration: underline;
    cursor: pointer;
}

.termsCloseIcon {
    position: absolute;
    text-align: right;
    z-index: 99999 !important;
    cursor: pointer; /* Add a pointer on hover */
    border: 2px solid #e4e8e9 !important;
}

.termsCloseIcon:hover {
    background: #b2c8e0;
    border: 2px solid #4c616b;
}

.termsCloseIconTop .termsCloseIcon a {
    padding-right: 8px;
}


.termsContainer {
    position: relative;
    top: 0px;
    display: none;
}

.termsTextBox {
    position: relative;
    background-color: white;
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    height: auto !important;
}

#termsText {
    padding: 20px;
    color: black;
    line-height: 1.2;
    font-size: 0.9em;
    padding: 2px 2px 2px 5px !important;
    a:not(.termsCloseMark) {
        text-decoration: underline;
    }
}

#termsText h4 {
    margin-top: 42px;
    font-size: 21px;
}

#termsText p {
    padding-top: 10px;
}

.termsCloseIconBottom {
    bottom: -35px !important;
}

.termsCloseMark {
    padding: 2px;
    color: #b2c8e0;
}

.termsWrapper {
    border: 2px solid #f0f0f0;
    padding-bottom: 25px;
    width: auto;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

 