﻿.c-hero{
	@media #{$small-up} {
		height: 65vw;
		position: relative;
		overflow: hidden;
	}
	@media #{$large-up}{
		height: 50vw;
	}
}

.c-hero__image{
	@media #{$small-up}{
		position: absolute;
		bottom: 30%;
		left: 50%;
		transform: translate(-50%, 25%);
		width: 100%;
	}
}

.c-hero__heading{
	position: absolute;
    top: 30%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 3.5vw;
}
