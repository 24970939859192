﻿.skip-link {
	position: absolute;
	top: 0;
	left: 0;
}

.skip-link__link {
	opacity: 0;
	pointer-events: none;
}

.skip-link__link:focus {
	opacity: 1;
	display: block;
	pointer-events: all;
}


//MOBILE MENU end
//SHORTCUT TO THE TOP start
.to-top {
	background-color: rgba(0, 0, 0, 0.3);
	width: 50px;
	height: 50px;
	padding: 2px 6px;
	margin: 0;
	color: #fff;
	opacity: 0.5;
	position: fixed;
	bottom: 10px;
	right: 10px;
	text-decoration: none;
	transition: opacity ease-in 0.2s;
	background-image: url("../Icons/arrow-up.svg");
	background-size: 30px 30px;
	background-position: center;
	background-repeat: no-repeat;
	text-indent: -9999px;
	z-index: 99;
}

.to-top:hover {
	opacity: 1;
	color: #FFF;
}


.site-header {
	@media #{$small-only}{
		padding: $tiny-padding 0;
		margin-bottom: $medium-margin;
	}
	@media #{$medium-up} {
		height: 80px;
		display: table;
	}
	@media #{$large-up} {
		height: 130px;
		display: table;
	}
}


.site-header {
	@include full-row();
	width: 100%;
	@media #{$medium-up}{
		position: absolute;
		z-index: 500;
		top: 0;
	}
}

.c-menu{
	position: relative;
	overflow: hidden;
	@media #{$medium-up}{
		display: table-cell;
		vertical-align: middle;
	}
}
.site-logo {
	display: inline-block;
	float: left;

	svg {
		vertical-align: top;
	}

	@media #{$small-only} {
		width: 50%;
		float: none;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

.site-logo path {
	stroke-dasharray: 0;
	animation: dash 5s linear;
}

@keyframes dash {
	to {
		stroke-dashoffset: 10000;
	}
}

.l-menu {
	@media #{$small-only} {
		float: right;
	}

	@media #{$medium-up} {
		width: calc(100% - 250px);
	}

	text-align: right;
	display: inline-block;
}

.menu-button {
	display: none;
}

.main-nav-item {
	font-family: $heading-font-family;
	font-size: scale(1);
	line-height: 1;
	margin-left: 1em;
	padding: 5px 10px;
	
	@media #{$medium-up} {
		display: inline-block;
		padding: 0px 10px;
		line-height: 50px;
	}
}

.main-nav-item a {
	text-decoration: none;
	color: $black;
}
