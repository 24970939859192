

[class*="column"]{
	display: block;
	float: left;
}

@mixin size($size, $number){
	.#{$size}-#{$number} {
		@include span-columns($number);		
	}
	.#{$size}-push-#{$number}{
		@include shift($number);
	}
}

@media #{$small-up} {
	@for $i from 1 through $grid-columns {
		@include size(s-up, $i);
	}
}

@media #{$small-only} {
	@for $i from 1 through $grid-columns {
		@include size(s, $i);
	}
}


@media #{$medium-up} {
	@for $i from 1 through $grid-columns {
		@include size(m-up, $i);
	}
}

@media #{$medium-only} {
	@for $i from 1 through $grid-columns {
		@include size(m, $i);
	}
}

@media #{$large-up}{
	@for $i from 1 through $grid-columns {
		@include size(l-up, $i);
	}
}

@media #{$large-only}{
	@for $i from 1 through $grid-columns {
		@include size(l, $i);
	}
}




.row{
	@include outer-container();
}
