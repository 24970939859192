﻿img, svg {
    max-width: 100%;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.overflow {
    overflow: auto;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.center-block.column {
    margin: 0 auto;
    float: none;
}

.module{
	margin-bottom: $large-margin;
}

//PADDINGS & MARGINS start
.padding {
    padding: $medium-padding 0;
}
.all-padding-tiny {
    padding: $tiny-padding;
}
.all-padding-small {
    padding: $small-padding;
}
.all-padding-medium {
    padding: $medium-padding;
}
.all-padding-large {
    padding: $large-padding;
}
.bottom-padding-tiny {
    padding-bottom: $tiny-padding;
}
.bottom-padding-small {
    padding-bottom: $small-padding;
}
.bottom-padding-medium {
    padding-bottom: $medium-padding;
}
.bottom-padding-large {
    padding-bottom: $large-padding;
}
.margin {
    margin: $medium-margin 0;
}
.all-margin-tiny {
    margin: $tiny-margin;
}
.all-margin-small {
    margin: $small-margin;
} 
.all-margin-medium {
    margin: $medium-margin;
}
.all-margin-large {
    margin: $large-margin;
}
.bottom-margin-tiny {
    margin-bottom: $tiny-margin;
}
.bottom-margin-small {
    margin-bottom: $small-margin;
}
.bottom-margin-medium {
    margin-bottom: $medium-margin;
}
.bottom-margin-large {
    margin-bottom: $large-margin;
}
.top-margin-tiny {
    margin-top: $tiny-margin;
}
.top-margin-small {
    margin-top: $small-margin;
}
.top-margin-medium {
    margin-top: $medium-margin;
}
.top-margin-large {
    margin-top: $large-margin;
}
//PADDINGS & MARGINS end
//BACKGROUNDS start
//BACKGROUNDS end  
//BLOG start
.blog__image {
    width:100%;
}
//BLOG end