﻿.c-white-card{
	background-color: white;
	$border: 4px solid #EAF1F4;
	> *{
		border: $border;
		border-top: 0;
		border-bottom: 0;
	}
	> *:first-child{
		border-top: $border
	}
	> *:last-child{
		border-bottom: $border;
	}
}
