﻿
footer {
    .horizontal-list{
        padding-right: 2px;
    }
}
.footer-section{
	margin-bottom: $small-margin;
}

.footer-info-wrapper {
    background-color: #bbccbd;
    margin-top: 25px;
}

.footer-sub-section {
    @media #{$large-up} {
        margin-top: 75px;
    }
}

.footer-heading {
    font-family: $heading-font-family;
    display: block;
}

.social-icons-wrapper {
    width: auto;
}

.rotvoll-text {
    width: 220px;
}

.social-icons {
    max-width: 45% !important;
    padding: 3px;
}