@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

/* Don't kill focus outline for keyboard users: http://24ways.org/2009/dont-lose-your-focus */
a:hover, a:active {
  outline: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

/**
 * @name Base
 * @description These are your basic text styles
 */
@font-face {
  font-family: 'Plantin';
  src: url("../Fonts/3146D1_0_0.eot");
  src: url("../Fonts/3146D1_0_0.eot?#iefix") format("embedded-opentype"), url("../Fonts/3146D1_0_0.woff2") format("woff2"), url("../Fonts/3146D1_0_0.woff") format("woff"), url("../Fonts/3146D1_0_0.ttf") format("truetype");
  font-weight: bold; }

@font-face {
  font-family: 'Plantin';
  src: url("../Fonts/3146D1_2_0.eot");
  src: url("../Fonts/3146D1_2_0.eot?#iefix") format("embedded-opentype"), url("../Fonts/3146D1_2_0.woff2") format("woff2"), url("../Fonts/3146D1_2_0.woff") format("woff"), url("../Fonts/3146D1_2_0.ttf") format("truetype");
  font-style: italic; }

@font-face {
  font-family: 'Plantin';
  src: url("../Fonts/3146D1_3_0.eot");
  src: url("../Fonts/3146D1_3_0.eot?#iefix") format("embedded-opentype"), url("../Fonts/3146D1_3_0.woff2") format("woff2"), url("../Fonts/3146D1_3_0.woff") format("woff"), url("../Fonts/3146D1_3_0.ttf") format("truetype");
  font-weight: normal; }

:root {
  font-size: 16px; }

body {
  color: #16161B;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  line-height: 1.44; }

a, .link, .typography a {
  color: currentColor;
  text-decoration: none;
  transition: color 300ms ease-in-out; }
  a:hover, .link:hover, .typography a:hover {
    color: #26555a; }
  a:visited, .link:visited, .typography a:visited {
    color: currentColor; }

.link, .typography a {
  text-decoration: underline; }

a.no-link-style {
  color: #16161B;
  text-decoration: none; }

h1, h2, h3, h4, h5, h6,
.h1,
.typography h1,
.typography .h1, .h2, .typography h2, .typography .h2, .h3, .typography h3, .typography .h3, .h4, .typography h4, .typography .h4, .h5, .typography h5, .typography .h5, .h6, .typography h6, .typography .h6 {
  color: currentColor; }

.h1, .typography h1, .typography .h1, .h2, .typography h2, .typography .h2, .h3, .typography h3, .typography .h3, .h4, .typography h4, .typography .h4, .h5, .typography h5, .typography .h5, .h6, .typography h6, .typography .h6 {
  font-family: "Plantin", serif;
  font-weight: bold; }

.h1, .typography h1, .typography .h1, .h2, .typography h2, .typography .h2 {
  line-height: 1.3; }

.h3, .typography h3, .typography .h3, .h4, .typography h4, .typography .h4 {
  line-height: 1.3; }

.h1, .typography h1, .typography .h1, h1 {
  font-size: 2.48832rem; }
  @media only screen and (min-width: 64.0625em) {
    .h1, .typography h1, .typography .h1, h1 {
      font-size: 2.98598rem; } }

.h2, .typography h2, .typography .h2, h2 {
  font-size: 2.0736rem; }
  @media only screen and (min-width: 64.0625em) {
    .h2, .typography h2, .typography .h2, h2 {
      font-size: 2.48832rem; } }

.h3, .typography h3, .typography .h3, h3 {
  font-size: 1.44rem; }
  @media only screen and (min-width: 64.0625em) {
    .h3, .typography h3, .typography .h3, h3 {
      font-size: 2.0736rem; } }

.h4, .typography h4, .typography .h4, h4 {
  font-size: 1.44rem; }
  @media only screen and (min-width: 64.0625em) {
    .h4, .typography h4, .typography .h4, h4 {
      font-size: 1.728rem; } }

.h5, .typography h5, .typography .h5, h5 {
  font-size: 1.2rem; }
  @media only screen and (min-width: 64.0625em) {
    .h5, .typography h5, .typography .h5, h5 {
      font-size: 1.44rem; } }

.h6, .typography h6, .typography .h6, h6 {
  font-size: 1em; }

.h1.not-bold, .typography h1.not-bold,
.h2.not-bold,
.typography h2.not-bold,
.h3.not-bold,
.typography h3.not-bold,
.h4.not-bold,
.typography h4.not-bold,
.h5.not-bold,
.typography h5.not-bold,
.h6.not-bold,
.typography h6.not-bold {
  font-weight: normal; }

.alternate-heading {
  color: #16161B; }

.smaller {
  font-size: 80%; }

.larger {
  font-size: 120%; }

.italic, .typography i, .typography em {
  font-style: italic; }

.bold, .typography b, .typography strong {
  font-weight: 700; }

.uppercase {
  text-transform: uppercase; }

.center {
  text-align: center; }

blockquote, q {
  quotes: none;
  border: 2px solid #aaccda;
  padding: 2em;
  margin: 3rem auto 3rem;
  text-align: center;
  font-weight: normal;
  font-family: "Plantin", serif;
  position: relative;
  width: 90%;
  font-size: 1.5rem;
  quotes: "“" "”" "‘" "’"; }

blockquote:after, blockquote:before {
  content: open-quote;
  color: #000;
  font-family: "Plantin";
  position: absolute;
  top: -0.5em;
  font-size: 1.5em;
  line-height: 1em;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 20px;
  background-color: #eaf1f4; }

blockquote:after {
  content: close-quote;
  bottom: -1em;
  top: auto;
  /* line-height: 1em; */ }

@media only screen and (max-width: 40em) {
  .s-center {
    text-align: center; } }

@media only screen {
  .s-up-center {
    text-align: center; } }

@media only screen and (min-width: 40.0625em) and (max-width: 64em) {
  .m-center {
    text-align: center; } }

@media only screen and (min-width: 40.0625em) {
  .m-up-center {
    text-align: center; } }

@media only screen and (min-width: 64.0625em) {
  .l-center {
    text-align: center; } }

@media only screen and (min-width: 64.0625em) and (max-width: 90em) {
  .l-up-center {
    text-align: center; } }

.unordered-list, .typography ul {
  list-style-type: disc; }

.ordered-list, .typography ol {
  list-style: decimal; }

.horizontal-list > * {
  display: inline-block;
  list-style: none; }

.block-margins, .typography p, .typography ul, .typography ol, .typography pre {
  margin: 1em 0; }

.small-links {
  font-size: 0.85em; }

.typography h1, .typography h1, .typography .h1 {
  margin: 1em 0 0.5em; }

.typography h2, .typography h2, .typography .h2 {
  margin: 1em 0 0.5em; }

.typography h3, .typography h3, .typography .h3 {
  margin: 1em 0 0.5em; }

.typography h4, .typography h4, .typography .h4 {
  margin: 1em 0 0.5em; }

.typography h5, .typography h5, .typography .h5 {
  margin: 1em 0 0.5em; }

.typography h6, .typography h6, .typography .h6 {
  margin: 1em 0 0.5em; }

.typography ul, .typography ol {
  list-style-position: inside;
  padding-left: 1em; }

.main-content {
  word-wrap: break-word; }

[class*="column"] {
  display: block;
  float: left; }

@media only screen {
  .s-up-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%; }
    .s-up-1:last-child {
      margin-right: 0; }
  .s-up-push-1 {
    margin-left: 8.5298%; }
  .s-up-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%; }
    .s-up-2:last-child {
      margin-right: 0; }
  .s-up-push-2 {
    margin-left: 17.05961%; }
  .s-up-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%; }
    .s-up-3:last-child {
      margin-right: 0; }
  .s-up-push-3 {
    margin-left: 25.58941%; }
  .s-up-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%; }
    .s-up-4:last-child {
      margin-right: 0; }
  .s-up-push-4 {
    margin-left: 34.11922%; }
  .s-up-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%; }
    .s-up-5:last-child {
      margin-right: 0; }
  .s-up-push-5 {
    margin-left: 42.64902%; }
  .s-up-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
    .s-up-6:last-child {
      margin-right: 0; }
  .s-up-push-6 {
    margin-left: 51.17883%; }
  .s-up-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%; }
    .s-up-7:last-child {
      margin-right: 0; }
  .s-up-push-7 {
    margin-left: 59.70863%; }
  .s-up-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%; }
    .s-up-8:last-child {
      margin-right: 0; }
  .s-up-push-8 {
    margin-left: 68.23843%; }
  .s-up-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%; }
    .s-up-9:last-child {
      margin-right: 0; }
  .s-up-push-9 {
    margin-left: 76.76824%; }
  .s-up-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%; }
    .s-up-10:last-child {
      margin-right: 0; }
  .s-up-push-10 {
    margin-left: 85.29804%; }
  .s-up-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%; }
    .s-up-11:last-child {
      margin-right: 0; }
  .s-up-push-11 {
    margin-left: 93.82785%; }
  .s-up-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    .s-up-12:last-child {
      margin-right: 0; }
  .s-up-push-12 {
    margin-left: 102.35765%; } }

@media only screen and (max-width: 40em) {
  .s-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%; }
    .s-1:last-child {
      margin-right: 0; }
  .s-push-1 {
    margin-left: 8.5298%; }
  .s-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%; }
    .s-2:last-child {
      margin-right: 0; }
  .s-push-2 {
    margin-left: 17.05961%; }
  .s-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%; }
    .s-3:last-child {
      margin-right: 0; }
  .s-push-3 {
    margin-left: 25.58941%; }
  .s-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%; }
    .s-4:last-child {
      margin-right: 0; }
  .s-push-4 {
    margin-left: 34.11922%; }
  .s-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%; }
    .s-5:last-child {
      margin-right: 0; }
  .s-push-5 {
    margin-left: 42.64902%; }
  .s-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
    .s-6:last-child {
      margin-right: 0; }
  .s-push-6 {
    margin-left: 51.17883%; }
  .s-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%; }
    .s-7:last-child {
      margin-right: 0; }
  .s-push-7 {
    margin-left: 59.70863%; }
  .s-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%; }
    .s-8:last-child {
      margin-right: 0; }
  .s-push-8 {
    margin-left: 68.23843%; }
  .s-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%; }
    .s-9:last-child {
      margin-right: 0; }
  .s-push-9 {
    margin-left: 76.76824%; }
  .s-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%; }
    .s-10:last-child {
      margin-right: 0; }
  .s-push-10 {
    margin-left: 85.29804%; }
  .s-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%; }
    .s-11:last-child {
      margin-right: 0; }
  .s-push-11 {
    margin-left: 93.82785%; }
  .s-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    .s-12:last-child {
      margin-right: 0; }
  .s-push-12 {
    margin-left: 102.35765%; } }

@media only screen and (min-width: 40.0625em) {
  .m-up-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%; }
    .m-up-1:last-child {
      margin-right: 0; }
  .m-up-push-1 {
    margin-left: 8.5298%; }
  .m-up-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%; }
    .m-up-2:last-child {
      margin-right: 0; }
  .m-up-push-2 {
    margin-left: 17.05961%; }
  .m-up-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%; }
    .m-up-3:last-child {
      margin-right: 0; }
  .m-up-push-3 {
    margin-left: 25.58941%; }
  .m-up-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%; }
    .m-up-4:last-child {
      margin-right: 0; }
  .m-up-push-4 {
    margin-left: 34.11922%; }
  .m-up-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%; }
    .m-up-5:last-child {
      margin-right: 0; }
  .m-up-push-5 {
    margin-left: 42.64902%; }
  .m-up-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
    .m-up-6:last-child {
      margin-right: 0; }
  .m-up-push-6 {
    margin-left: 51.17883%; }
  .m-up-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%; }
    .m-up-7:last-child {
      margin-right: 0; }
  .m-up-push-7 {
    margin-left: 59.70863%; }
  .m-up-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%; }
    .m-up-8:last-child {
      margin-right: 0; }
  .m-up-push-8 {
    margin-left: 68.23843%; }
  .m-up-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%; }
    .m-up-9:last-child {
      margin-right: 0; }
  .m-up-push-9 {
    margin-left: 76.76824%; }
  .m-up-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%; }
    .m-up-10:last-child {
      margin-right: 0; }
  .m-up-push-10 {
    margin-left: 85.29804%; }
  .m-up-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%; }
    .m-up-11:last-child {
      margin-right: 0; }
  .m-up-push-11 {
    margin-left: 93.82785%; }
  .m-up-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    .m-up-12:last-child {
      margin-right: 0; }
  .m-up-push-12 {
    margin-left: 102.35765%; } }

@media only screen and (min-width: 40.0625em) and (max-width: 64em) {
  .m-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%; }
    .m-1:last-child {
      margin-right: 0; }
  .m-push-1 {
    margin-left: 8.5298%; }
  .m-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%; }
    .m-2:last-child {
      margin-right: 0; }
  .m-push-2 {
    margin-left: 17.05961%; }
  .m-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%; }
    .m-3:last-child {
      margin-right: 0; }
  .m-push-3 {
    margin-left: 25.58941%; }
  .m-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%; }
    .m-4:last-child {
      margin-right: 0; }
  .m-push-4 {
    margin-left: 34.11922%; }
  .m-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%; }
    .m-5:last-child {
      margin-right: 0; }
  .m-push-5 {
    margin-left: 42.64902%; }
  .m-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
    .m-6:last-child {
      margin-right: 0; }
  .m-push-6 {
    margin-left: 51.17883%; }
  .m-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%; }
    .m-7:last-child {
      margin-right: 0; }
  .m-push-7 {
    margin-left: 59.70863%; }
  .m-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%; }
    .m-8:last-child {
      margin-right: 0; }
  .m-push-8 {
    margin-left: 68.23843%; }
  .m-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%; }
    .m-9:last-child {
      margin-right: 0; }
  .m-push-9 {
    margin-left: 76.76824%; }
  .m-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%; }
    .m-10:last-child {
      margin-right: 0; }
  .m-push-10 {
    margin-left: 85.29804%; }
  .m-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%; }
    .m-11:last-child {
      margin-right: 0; }
  .m-push-11 {
    margin-left: 93.82785%; }
  .m-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    .m-12:last-child {
      margin-right: 0; }
  .m-push-12 {
    margin-left: 102.35765%; } }

@media only screen and (min-width: 64.0625em) {
  .l-up-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%; }
    .l-up-1:last-child {
      margin-right: 0; }
  .l-up-push-1 {
    margin-left: 8.5298%; }
  .l-up-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%; }
    .l-up-2:last-child {
      margin-right: 0; }
  .l-up-push-2 {
    margin-left: 17.05961%; }
  .l-up-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%; }
    .l-up-3:last-child {
      margin-right: 0; }
  .l-up-push-3 {
    margin-left: 25.58941%; }
  .l-up-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%; }
    .l-up-4:last-child {
      margin-right: 0; }
  .l-up-push-4 {
    margin-left: 34.11922%; }
  .l-up-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%; }
    .l-up-5:last-child {
      margin-right: 0; }
  .l-up-push-5 {
    margin-left: 42.64902%; }
  .l-up-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
    .l-up-6:last-child {
      margin-right: 0; }
  .l-up-push-6 {
    margin-left: 51.17883%; }
  .l-up-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%; }
    .l-up-7:last-child {
      margin-right: 0; }
  .l-up-push-7 {
    margin-left: 59.70863%; }
  .l-up-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%; }
    .l-up-8:last-child {
      margin-right: 0; }
  .l-up-push-8 {
    margin-left: 68.23843%; }
  .l-up-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%; }
    .l-up-9:last-child {
      margin-right: 0; }
  .l-up-push-9 {
    margin-left: 76.76824%; }
  .l-up-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%; }
    .l-up-10:last-child {
      margin-right: 0; }
  .l-up-push-10 {
    margin-left: 85.29804%; }
  .l-up-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%; }
    .l-up-11:last-child {
      margin-right: 0; }
  .l-up-push-11 {
    margin-left: 93.82785%; }
  .l-up-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    .l-up-12:last-child {
      margin-right: 0; }
  .l-up-push-12 {
    margin-left: 102.35765%; } }

@media only screen and (min-width: 64.0625em) and (max-width: 90em) {
  .l-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%; }
    .l-1:last-child {
      margin-right: 0; }
  .l-push-1 {
    margin-left: 8.5298%; }
  .l-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%; }
    .l-2:last-child {
      margin-right: 0; }
  .l-push-2 {
    margin-left: 17.05961%; }
  .l-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%; }
    .l-3:last-child {
      margin-right: 0; }
  .l-push-3 {
    margin-left: 25.58941%; }
  .l-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%; }
    .l-4:last-child {
      margin-right: 0; }
  .l-push-4 {
    margin-left: 34.11922%; }
  .l-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%; }
    .l-5:last-child {
      margin-right: 0; }
  .l-push-5 {
    margin-left: 42.64902%; }
  .l-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
    .l-6:last-child {
      margin-right: 0; }
  .l-push-6 {
    margin-left: 51.17883%; }
  .l-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%; }
    .l-7:last-child {
      margin-right: 0; }
  .l-push-7 {
    margin-left: 59.70863%; }
  .l-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%; }
    .l-8:last-child {
      margin-right: 0; }
  .l-push-8 {
    margin-left: 68.23843%; }
  .l-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%; }
    .l-9:last-child {
      margin-right: 0; }
  .l-push-9 {
    margin-left: 76.76824%; }
  .l-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%; }
    .l-10:last-child {
      margin-right: 0; }
  .l-push-10 {
    margin-left: 85.29804%; }
  .l-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%; }
    .l-11:last-child {
      margin-right: 0; }
  .l-push-11 {
    margin-left: 93.82785%; }
  .l-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    .l-12:last-child {
      margin-right: 0; }
  .l-push-12 {
    margin-left: 102.35765%; } }

.row {
  max-width: 68em;
  margin-left: auto;
  margin-right: auto; }
  .row::after {
    clear: both;
    content: "";
    display: table; }

/**
 * @name Layout
 * @description Grids and things
 */
a.button, a .button, button.button, input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  text-decoration: none;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  text-align: center;
  font-style: italic;
  margin: 0 0 0;
  border: none;
  display: inline-block;
  background-color: transparent;
  border-radius: 0;
  color: #16161B;
  font-size: 1em;
  font-weight: normal;
  line-height: 22px;
  border: 3px solid #aaccda;
  transition: color 200ms, background-color 200ms, border 200ms;
  transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
  padding: 7px 14px 7px 14px;
  position: relative;
  overflow: hidden;
  text-decoration: none; }
  a.button:hover, a .button:hover, button.button:hover, input[type="submit"]:hover {
    background-color: transparent; }
  a.button:hover, a .button:hover, button.button:hover, input[type="submit"]:hover {
    border-color: #4c616b; }
  a.button:hover:after, a .button:hover:after, button.button:hover:after, input[type="submit"]:hover:after {
    transform: translate3d(0, 0, 0); }
  a.button:after, a .button:after, button.button:after, input[type="submit"]:after {
    content: '';
    position: absolute;
    top: 0;
    left: -3px;
    width: 105%;
    height: 105%;
    transform-origin: bottom left;
    transition: transform 0.15s cubic-bezier(0.22, 0.61, 0.36, 1);
    z-index: -1; }
  a.button:after, a .button:after, button.button:after, input[type="submit"]:after {
    background: #b2c8e0;
    transform: translate3d(0, -100%, 0) rotate(-30deg); }

input[type="submit"] {
  margin-top: 20px; }

input[type="submit"]:hover {
  background: #b2c8e0; }

input[type="reset"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  text-decoration: none;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  text-align: center;
  font-style: italic;
  margin: 0 0 0;
  border: none;
  display: inline-block;
  background-color: transparent;
  border-radius: 0;
  color: #16161B;
  font-size: 1em;
  font-weight: normal;
  line-height: 22px;
  border: 3px solid #aaccda;
  transition: color 200ms, background-color 200ms, border 200ms;
  transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
  padding: 7px 14px 7px 14px; }
  input[type="reset"]:hover {
    background-color: transparent; }

img, svg {
  max-width: 100%; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.overflow {
  overflow: auto; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.center-block.column {
  margin: 0 auto;
  float: none; }

.module {
  margin-bottom: 60px; }

.padding {
  padding: 40px 0; }

.all-padding-tiny {
  padding: 10px; }

.all-padding-small {
  padding: 20px; }

.all-padding-medium {
  padding: 40px; }

.all-padding-large {
  padding: 60px; }

.bottom-padding-tiny {
  padding-bottom: 10px; }

.bottom-padding-small {
  padding-bottom: 20px; }

.bottom-padding-medium {
  padding-bottom: 40px; }

.bottom-padding-large {
  padding-bottom: 60px; }

.margin {
  margin: 40px 0; }

.all-margin-tiny {
  margin: 10px; }

.all-margin-small {
  margin: 20px; }

.all-margin-medium {
  margin: 40px; }

.all-margin-large {
  margin: 60px; }

.bottom-margin-tiny {
  margin-bottom: 10px; }

.bottom-margin-small {
  margin-bottom: 20px; }

.bottom-margin-medium {
  margin-bottom: 40px; }

.bottom-margin-large {
  margin-bottom: 60px; }

.top-margin-tiny {
  margin-top: 10px; }

.top-margin-small {
  margin-top: 20px; }

.top-margin-medium {
  margin-top: 40px; }

.top-margin-large {
  margin-top: 60px; }

.blog__image {
  width: 100%; }

footer .horizontal-list {
  padding-right: 2px; }

.footer-section {
  margin-bottom: 20px; }

.footer-info-wrapper {
  background-color: #bbccbd;
  margin-top: 25px; }

@media only screen and (min-width: 64.0625em) {
  .footer-sub-section {
    margin-top: 75px; } }

.footer-heading {
  font-family: "Plantin", serif;
  display: block; }

.social-icons-wrapper {
  width: auto; }

.rotvoll-text {
  width: 220px; }

.social-icons {
  max-width: 45% !important;
  padding: 3px; }

.skip-link {
  position: absolute;
  top: 0;
  left: 0; }

.skip-link__link {
  opacity: 0;
  pointer-events: none; }

.skip-link__link:focus {
  opacity: 1;
  display: block;
  pointer-events: all; }

.to-top {
  background-color: rgba(0, 0, 0, 0.3);
  width: 50px;
  height: 50px;
  padding: 2px 6px;
  margin: 0;
  color: #fff;
  opacity: 0.5;
  position: fixed;
  bottom: 10px;
  right: 10px;
  text-decoration: none;
  transition: opacity ease-in 0.2s;
  background-image: url("../Icons/arrow-up.svg");
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -9999px;
  z-index: 99; }

.to-top:hover {
  opacity: 1;
  color: #FFF; }

@media only screen and (max-width: 40em) {
  .site-header {
    padding: 10px 0;
    margin-bottom: 40px; } }

@media only screen and (min-width: 40.0625em) {
  .site-header {
    height: 80px;
    display: table; } }

@media only screen and (min-width: 64.0625em) {
  .site-header {
    height: 130px;
    display: table; } }

.site-header {
  padding-left: calc(50vw - (64em / 2)) !important;
  padding-right: calc(50vw - (64em / 2)) !important;
  width: 100%; }
  @media only screen and (min-width: 40.0625em) {
    .site-header {
      position: absolute;
      z-index: 500;
      top: 0; } }

.c-menu {
  position: relative;
  overflow: hidden; }
  @media only screen and (min-width: 40.0625em) {
    .c-menu {
      display: table-cell;
      vertical-align: middle; } }

.site-logo {
  display: inline-block;
  float: left; }
  .site-logo svg {
    vertical-align: top; }
  @media only screen and (max-width: 40em) {
    .site-logo {
      width: 50%;
      float: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); } }

.site-logo path {
  stroke-dasharray: 0;
  animation: dash 5s linear; }

@keyframes dash {
  to {
    stroke-dashoffset: 10000; } }

.l-menu {
  text-align: right;
  display: inline-block; }
  @media only screen and (max-width: 40em) {
    .l-menu {
      float: right; } }
  @media only screen and (min-width: 40.0625em) {
    .l-menu {
      width: calc(100% - 250px); } }

.menu-button {
  display: none; }

.main-nav-item {
  font-family: "Plantin", serif;
  font-size: 1.2rem;
  line-height: 1;
  margin-left: 1em;
  padding: 5px 10px; }
  @media only screen and (min-width: 40.0625em) {
    .main-nav-item {
      display: inline-block;
      padding: 0px 10px;
      line-height: 50px; } }

.main-nav-item a {
  text-decoration: none;
  color: #16161B; }

/**
 * @name Forms
 * @order 3
 */
/**
 * @name Input fields
 * @state :focus - Focussed state
 * @state :hover - Hover state
 * @state .error - Error state
 * @markup
 * <form><label>Label:</label> <input type="text" class="{{{escaped}}}"></form>
 */
textarea, input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']):not([type='reset']) {
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
  padding: 5px 10px 10px;
  background-color: white;
  border-width: 0px;
  border-color: #000;
  border-radius: 0px;
  border-style: solid;
  color: #16161B;
  font-weight: bold;
  width: 100%;
  transition: border-color 200ms;
  border-bottom: 2px solid #aaccda; }
  textarea:focus, textarea:hover, input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']):not([type='reset']):focus, input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']):not([type='reset']):hover {
    outline: 0;
    border-color: #454545;
    background-color: white; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

.formTitle {
  display: none; }

.FormContainer.form-submitted {
  text-align: center;
  font-size: 140%; }

.LabelWrapper {
  width: 100%;
  background-color: white;
  padding-left: 12px;
  padding-top: 0.25em;
  font-size: 0.69444rem;
  letter-spacing: 0.1em; }

.FormFieldWrapper {
  margin-bottom: 1em; }

.termsLabel {
  text-decoration: underline;
  cursor: pointer; }

.termsCloseIcon {
  position: absolute;
  text-align: right;
  z-index: 99999 !important;
  cursor: pointer;
  /* Add a pointer on hover */
  border: 2px solid #e4e8e9 !important; }

.termsCloseIcon:hover {
  background: #b2c8e0;
  border: 2px solid #4c616b; }

.termsCloseIconTop .termsCloseIcon a {
  padding-right: 8px; }

.termsContainer {
  position: relative;
  top: 0px;
  display: none; }

.termsTextBox {
  position: relative;
  background-color: white;
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  height: auto !important; }

#termsText {
  padding: 20px;
  color: black;
  line-height: 1.2;
  font-size: 0.9em;
  padding: 2px 2px 2px 5px !important; }
  #termsText a:not(.termsCloseMark) {
    text-decoration: underline; }

#termsText h4 {
  margin-top: 42px;
  font-size: 21px; }

#termsText p {
  padding-top: 10px; }

.termsCloseIconBottom {
  bottom: -35px !important; }

.termsCloseMark {
  padding: 2px;
  color: #b2c8e0; }

.termsWrapper {
  border: 2px solid #f0f0f0;
  padding-bottom: 25px;
  width: auto;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

@media only screen {
  .c-hero {
    height: 65vw;
    position: relative;
    overflow: hidden; } }

@media only screen and (min-width: 64.0625em) {
  .c-hero {
    height: 50vw; } }

@media only screen {
  .c-hero__image {
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translate(-50%, 25%);
    width: 100%; } }

.c-hero__heading {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 3.5vw; }

.c-white-card {
  background-color: white; }
  .c-white-card > * {
    border: 4px solid #EAF1F4;
    border-top: 0;
    border-bottom: 0; }
  .c-white-card > *:first-child {
    border-top: 4px solid #EAF1F4; }
  .c-white-card > *:last-child {
    border-bottom: 4px solid #EAF1F4; }

.o-sigil {
  background-image: url("../Icons/rotvoll-sigill.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 80px;
  display: inline-block; }
  .o-sigil--small {
    width: 70px;
    height: 40px; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  background-color: #EAF1F4; }

@media only screen and (min-width: 40.0625em) {
  .content {
    padding-top: 80px; } }

@media only screen and (min-width: 64.0625em) {
  .content {
    padding-top: 130px; } }

@media only screen and (min-width: 40.0625em) {
  .u-pull-top {
    margin-top: -80px !important; } }

@media only screen and (min-width: 64.0625em) {
  .u-pull-top {
    margin-top: -130px !important; } }

@media only screen and (min-width: 40.0625em) {
  .u-pull-bottom {
    margin-bottom: -5vw !important; } }

.wrapper {
  padding: 0 10px; }
  @media only screen and (min-width: 64.0625em) {
    .wrapper {
      padding: 0; } }

img {
  vertical-align: top; }

.lazyload,
.lazyloading {
  opacity: 0;
  transform: translateX(-20px); }

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms ease-in; }

@keyframes reveal-image {
  0% {
    opacity: 0;
    clip-path: polygon(34% 0, 30% 0, 51% 100%, 54% 100%); }
  90% {
    opacity: 1;
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
    box-shadow: 0; }
  100% {
    opacity: 1;
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
    box-shadow: 1em 1.5em 30px rgba(117, 132, 138, 0.13); } }

.u-fake-ratio {
  overflow: hidden;
  position: relative; }
  .u-fake-ratio:after {
    padding-bottom: 135%;
    content: "";
    display: block; }
  .u-fake-ratio > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%; }

.h1.c-portal__heading, .typography h1.c-portal__heading {
  position: relative;
  width: 200%;
  font-size: 5.1rem; }
  @media only screen and (max-width: 40em) {
    .h1.c-portal__heading, .typography h1.c-portal__heading {
      font-size: 2.4832rem;
      line-height: 2.8rem;
      width: 100%; } }

.c-postcard {
  background-color: white;
  position: relative;
  z-index: 50;
  padding: 20px; }
  @media only screen and (max-width: 40em) {
    .c-postcard {
      display: flex;
      flex-flow: column; }
      .c-postcard__head {
        order: 1; } }
  .c-postcard__hero {
    display: none; }
    @media only screen and (min-width: 64.0625em) {
      .c-postcard__hero {
        display: block; } }

.c-article {
  margin-bottom: 60px; }
  @media only screen and (min-width: 40.0625em) and (max-width: 64em) {
    .c-article__head {
      margin-right: 0; } }
  @media only screen and (min-width: 40.0625em) {
    .c-article__image {
      margin-bottom: 3rem; } }
  .c-article__heading {
    line-height: 1;
    font-size: 2.98598rem;
    margin: 1rem 0 2rem; }
    @media only screen and (min-width: 64.0625em) {
      .c-article__heading {
        transform: translateX(-25%);
        width: 125%;
        margin: 2rem 0 2rem;
        font-size: 2.98598rem; } }
    @media only screen and (min-width: 64.0625em) {
      .c-article__heading {
        font-size: 4.29982rem; } }
  .c-article__description {
    font-weight: normal;
    font-size: 1.44rem;
    font-family: "Plantin", serif;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 64.0625em) {
      .c-article__description {
        font-size: 1.728rem; } }

.c-gallery__item {
  margin-bottom: 40px; }
  .c-gallery__item::after {
    clear: both;
    content: "";
    display: table; }
  @media only screen and (max-width: 40em) {
    .c-gallery__item {
      width: 90%;
      margin: 0 0 40px auto; } }
  @media only screen and (min-width: 40.0625em) and (max-width: 64em) {
    .c-gallery__item {
      width: 76.4%;
      margin: 0 0 40px auto; } }
  @media only screen and (min-width: 64.0625em) {
    .c-gallery__item {
      display: flex;
      flex-flow: row;
      margin-top: calc(-4em + -20px); } }

.c-gallery__item:last-child .cms-content {
  background-color: #dfe6d7;
  z-index: -1; }

.c-gallery__item:first-child {
  margin-top: 0; }

@media only screen and (min-width: 40.0625em) and (max-width: 64em) {
  .c-gallery__item:nth-child(odd) {
    margin: 0 auto 40px; }
    .c-gallery__item:nth-child(odd) .c-gallery__image {
      float: right;
      margin-left: 0;
      width: 100%; } }

@media only screen and (max-width: 40em) {
  .c-gallery__item:nth-child(odd) {
    margin: 0 auto 40px; }
    .c-gallery__item:nth-child(odd) .c-gallery__image {
      float: right;
      margin-left: 0;
      margin-bottom: 20px;
      width: 100%; } }

@media only screen and (min-width: 64.0625em) {
  .c-gallery__item:nth-child(even) .c-gallery__image {
    order: 1; } }

.c-gallery__image {
  width: 100%; }
  @media only screen and (max-width: 40em) {
    .c-gallery__image {
      width: 110%;
      margin-left: -10%; }
      .c-gallery__image.img--portrait {
        width: 60%;
        float: left;
        padding: 0 10px 0 0;
        margin-left: -15%; } }
  @media only screen and (min-width: 40.0625em) and (max-width: 64em) {
    .c-gallery__image {
      width: 75%;
      float: left;
      margin-left: -35%;
      padding: 10px 20px; } }
  @media only screen and (min-width: 64.0625em) {
    .c-gallery__image {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: left; } }

@media only screen and (min-width: 40.0625em) {
  .c-gallery__text {
    padding: 4em 20px 20px 20px; } }

@media only screen and (min-width: 64.0625em) {
  .c-gallery__text {
    width: 36%;
    padding: calc(20px + 4em) 20px calc(20px + 4em) 20px; } }

.c-gallery img {
  width: 100%;
  max-width: none; }

.c-link-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /*
	&__head{
		@include span-columns(5);
	}
	&__body{
		@include span-columns(7);
		@include omega;
	}
	*/ }
  @media only screen and (min-width: 40.0625em) and (max-width: 64em) {
    .c-link-row {
      padding: 0 10%; } }
  .c-link-row__item {
    display: flex;
    margin-bottom: 40px; }
    @media only screen and (min-width: 64.0625em) {
      .c-link-row__item {
        flex-flow: column;
        align-items: stretch; } }
  .c-link-row__item--no-image {
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #aaccda;
    padding: 10px; }
  @media only screen and (min-width: 40.0625em) and (max-width: 64em) {
    .c-link-row__body {
      display: flex;
      flex-flow: column;
      justify-content: center; } }

@media only screen and (min-width: 64.0625em) {
  .c-organic-row {
    display: flex;
    align-items: center;
    justify-content: center; } }

.c-organic-row__item {
  position: relative;
  margin-bottom: 40px; }

.c-organic-row__body {
  padding: 20px 0; }

@media only screen and (min-width: 64.0625em) {
  .c-organic-row__body .c-organic-row__heading {
    position: absolute;
    top: 0;
    right: 50%;
    width: 100%;
    transform: translateX(25%);
    font-size: 2.98598rem;
    z-index: 50; }
  .c-organic-row p {
    text-align: center;
    color: #30323a;
    padding: 0 20px; } }

.promote {
  background-color: #dfe6d7; }
  .promote a.button {
    border-color: #7d9087;
    text-decoration: none;
    width: 100%; }

.row footer {
  background-color: white; }
