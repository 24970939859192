﻿@import "Modules/Helpers/functions";
@import "../../../../Common/Skin/Vendor/index";

$site-max-width: 64em;


// Defined colors
$black: #16161B;
$white: #f0f0f0; 
$light-gray: #EAF1F4;

// Text colors 
$text-color: $black;
$quiet-color: $text-color;
$loud-color: $text-color;
$heading-color: $black;
$alternate-heading-color: $black;
$link-color: currentColor;
$link-hover-color: #26555a;
$link-visited-color: currentColor;
// Button colors
$secondary-button: $white;

// Harmon settings
// Se: http://www.modularscale.com/ för olika ratios
$base:  16px !default;
$ratio: $minor-third !default;
 

// Typography
// --- Body
$base-font-size: 1em;
$base-line-height: 1.9rem;
$base-font-family: 'Source Sans Pro', sans-serif;
$base-font-weight: normal;
$base-font-color: $text-color;
$bold-font-family: $base-font-family;

// --- Headings
$heading-font-family: 'Plantin', serif;
$heading-font-weight: bold;
$heading-font-color: $heading-color;


// Buttons settings
$border-radius: 0;
$button-color: $text-color !default;
$button-font: $base-font-family;
$button-font-size: 1em;
$button-weight: normal;
$button-line-height: 22px;
$button-text-align: center !default;
$button-bg-color: transparent !default;
$button-margin-bottom: 0;
$button-display: inline-block;


// Forms settings

$form-font-size: 18px;
$form-font-family: $base-font-family;
$form-padding: 10px;
$form-border-width: 0px;
$form-border-color: #000;
$form-border-radius: 0px;
$form-border-style: solid;
$form-text-color: $text-color;
$form-focus-bg: white;
$form-focus-border: #454545;
$form-bg: white;


// Margins & Paddings
// These are used throughout the site to be consistent.
$large-padding: 60px;
$medium-padding: 40px;
$small-padding: 20px;
$tiny-padding: 10px;

$large-margin: 60px;
$medium-margin: 40px;
$small-margin: 20px;
$tiny-margin: 10px;

// Site specific variables:
$circle-diameter: 70px;
$circle-offset: 20px;
$default-border: 1px solid $black;

// Neat Settings
// Must appear above the import of Neat.
$grid-columns: 12 !default;
$visual-grid: false !default;
$visual-grid-color: yellow !default;
$visual-grid-index: front !default;
$visual-grid-opacity: 0.3 !default;

@import "Modules/index";