﻿@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600);


@font-face {
	font-family: 'Plantin';
	src: url('../Fonts/3146D1_0_0.eot');
	src: url('../Fonts/3146D1_0_0.eot?#iefix') format('embedded-opentype'),url('../Fonts/3146D1_0_0.woff2') format('woff2'),url('../Fonts/3146D1_0_0.woff') format('woff'),url('../Fonts/3146D1_0_0.ttf') format('truetype');
	font-weight: bold;
}
@font-face {
	font-family: 'Plantin';
	src: url('../Fonts/3146D1_2_0.eot');
	src: url('../Fonts/3146D1_2_0.eot?#iefix') format('embedded-opentype'),url('../Fonts/3146D1_2_0.woff2') format('woff2'),url('../Fonts/3146D1_2_0.woff') format('woff'),url('../Fonts/3146D1_2_0.ttf') format('truetype');
	font-style: italic;
}


@font-face {
	font-family: 'Plantin';
	src: url('../Fonts/3146D1_3_0.eot');
	src: url('../Fonts/3146D1_3_0.eot?#iefix') format('embedded-opentype'),url('../Fonts/3146D1_3_0.woff2') format('woff2'),url('../Fonts/3146D1_3_0.woff') format('woff'),url('../Fonts/3146D1_3_0.ttf') format('truetype');
	font-weight: normal;
}
