// CSS Reset
// See: https://github.com/jasonkarns/css-reset


// Bourbon
// -- Docs: http://bourbon.io/docs/

// Bourbon Neat:
// -- Docs: http://thoughtbot.github.io/neat-docs/latest

// Ceaser Animation Tool
// -- See: http://matthewlein.com/ceaser/

// Helpers
@import "settings";

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}


.footer {
    //background-color: #bbccbd;
}

body {
	background-color: $light-gray;
}


.content{
	// header is position absolute and has a height of 130.
	// This padding is used to offset this, while allowing us to use a big ass hero image.
	@media #{$medium-up}{
		padding-top: 80px;
	}
	@media #{$large-up}{
		padding-top: 130px;
	}
}
.u-pull-top{
	@media #{$medium-up}{
		margin-top: -80px !important;
	}
	@media #{$large-up}{
		margin-top: -130px !important;
	}
}

.u-pull-bottom{
	@media #{$medium-up}{
		margin-bottom: -5vw !important;
	}
}

.wrapper {
	padding: 0 $tiny-padding;

	@media #{$large-up} {
		padding: 0;
	}
}

img {
	vertical-align: top;
}

.lazyload, .lazyloaded {
}

.lazyload,
.lazyloading {
	opacity: 0;
	//clip-path: polygon(34% 0, 30% 0, 51% 100%, 54% 100%); // classy
	//clip-path: polygon(54% 57%, 55% 42%, 35% 43%, 35% 57%); // oväntad
	transform: translateX(-20px);
}

.lazyloaded {
	//transition: opacity 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-clip-path 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 200ms;
	opacity: 1;
	transition: opacity 300ms ease-in;
	//opacity: 0;
	//animation: reveal-image 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 500ms forwards;
}

@keyframes reveal-image {
	0% {
		opacity: 0;
		clip-path: polygon(34% 0, 30% 0, 51% 100%, 54% 100%);
	}

	90% {
		opacity: 1;
		clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
		box-shadow: 0;
	}

	100% {
		opacity: 1;
		clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
		box-shadow: 1em 1.5em 30px rgba(117, 132, 138, 0.13);
	}
}


.u-fake-ratio {
	// important that this is combined with width
	overflow: hidden;
	position: relative;

	&:after {
		padding-bottom: 135%;
		content: "";
		display: block;
	}

	> img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
	}
}


.h1.c-portal__heading {
    position: relative;
    width: 200%;
    font-size: 5.1rem;

    @media #{$small-only}{
		font-size: 2.4832rem;
        line-height: 2.8rem;
        width: 100%;
	}
}

.c-postcard {
	background-color: white;
	position: relative;
	z-index: 50;
	padding: $small-padding;

	@media #{$small-only} {
		display: flex;
		flex-flow: column;

		&__head {
			order: 1;

		}

	}

	&__hero{
		display: none;
		@media #{$large-up}{
			display: block;
		}
	}

}

.c-article {
	margin-bottom: $large-margin;

	&__head {
		@media #{$medium-only} {
			@include omega;
		}
	}

	&__image {
		@media #{$medium-up} {
			margin-bottom: 3rem;
		}
	}

	&__heading {
		line-height: 1;
		font-size: scale(6);
		margin: 1rem 0 2rem;

		@media #{$large-up} {
			transform: translateX(-25%);
			width: 125%;
			margin: 2rem 0 2rem;
			font-size: scale(6);
		}

		@media #{$large-up} {
			font-size: scale(8);
		}
	}

	&__description {
		font-weight: normal;
		font-size: scale(2);
		font-family: $heading-font-family;
		margin-bottom: 1rem;

		@media #{$large-up} {
			font-size: scale(3);
		}
	}
}

.c-gallery {

	&__item {
		@include clearfix;
		margin-bottom: $medium-margin;

		@media #{$small-only} {
			width: 90%;
			margin: 0 0 $medium-margin auto;
		}

		@media #{$medium-only} {
			width: 76.4%;
			margin: 0 0 $medium-margin auto;
		}

		@media #{$large-up} {
			display: flex;
			flex-flow: row;
			margin-top: calc(-4em + -20px);
		}
	}
	&__item:last-child .cms-content{
		background-color: rgb(223, 230, 215);
		z-index: -1;
	}

	&__item:first-child {
		margin-top: 0;
	}

	@media #{$medium-only} {
		&__item:nth-child(odd) {
			margin: 0 auto 40px;

			.c-gallery__image {
				float: right;
				margin-left: 0;
				width: 100%;
			}
		}
	}
	@media #{$small-only} {
		&__item:nth-child(odd) {
			margin: 0 auto 40px;

			.c-gallery__image {
				float: right;
				margin-left: 0;
				margin-bottom: 20px;
				width: 100%;
			}
		}
	}

	@media #{$large-up} {
		&__item:nth-child(even) {
			.c-gallery__image {
				order: 1;
			}
		}
	}


	&__image {
		width: 100%;

		@media #{$small-only} {
			width: 110%;
			//margin-bottom: $small-margin;
			margin-left: -10%;
			// om inte det här funkar så ser det vart fall bra ut ändå!
			&.img--portrait {
				width: 60%;
				float: left;
				padding: 0 10px 0 0;
				margin-left: -15%;
			}

			&.img--landscape {
			}
		}

		@media #{$medium-only} {
			width: 75%;
			float: left;
			margin-left: -35%;
			padding: $tiny-padding $small-padding;

			&.img--landscape {
			}
		}

		@media #{$large-up} {
			width: 70%;
			display: flex;
			align-items: center;
			justify-content: left;
		}

		img.lazyloaded {

			@media #{$medium-up} {
				//box-shadow: -5px 5px 20px rgba(91, 91, 91, 0.3);
			}

			@media #{$large-up} {
				//box-shadow: 1em 1.5em 30px rgba(117, 132, 138, 0.13);
			}
		}
	}

	&__text {
		@media #{$medium-up} {
			padding: 4em 20px 20px 20px;
		}

		@media #{$large-up} {
			width: 36%;
			padding: calc(20px + 4em) 20px calc(20px + 4em) 20px;
		}
	}
	img{
		width: 100%;
		max-width: none;
	}
}



.c-link-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	@media #{$medium-only} {
		padding: 0 10%;
	}

	&__item {
		display: flex;
		margin-bottom: $medium-margin;

		@media #{$large-up} {
			flex-flow: column;
			align-items: stretch;
		}
	}

	&__item--no-image {
		align-items: center;
		justify-content: center;
		text-align: center;
		background-color: #aaccda;
		padding: 10px;
	}


	&__body {
		@media #{$medium-only} {
			display: flex;
			flex-flow: column;
			justify-content: center;
		}
	}
	/*
	&__head{
		@include span-columns(5);
	}
	&__body{
		@include span-columns(7);
		@include omega;
	}
	*/
}



.c-organic-row {
	@media #{$large-up} {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__item {
		position: relative;
		margin-bottom: $medium-margin;
	}

	&__body {
		padding: $small-padding 0;
	}

	@media #{$large-up} {
		&__body &__heading {
			position: absolute;
			top: 0;
			right: 50%;
			width: 100%;
			transform: translateX(25%);
			font-size: scale(6);
			z-index: 50;
		}

		p {
			
			text-align: center;
			color: #30323a;
			padding: 0 20px;
		}
	}
}

.promote {
    background-color: #dfe6d7;
    a.button {
        border-color: #7d9087;
        text-decoration: none;
        width: 100%;
    }
}

.row footer {
    background-color: white;
}

